import { notificationSystem } from '@/assets/notifications.json';

export default {
  data: () => ({
    notificationSystem,
  }),
  methods: {
    showErrors(error) {
      error.messages.forEach((item) => {
        this.$toast.error(item, 'Erro!', this.notificationSystem.options.error);
      });
    },
  },
};
