<template>
  <div id="app">
    <b-navbar
        class="position-fixed w-100"
        toggleable="lg"
        type="dark"
        id="menu-top"
        variant="primary">
      <div class="w-100">
        <b-row>
          <b-col>
            <b-button variant="default" class="text-light" @click="toggleMenu">
              <i class="fas fa-bars"></i> MENU
            </b-button>
          </b-col>

          <b-col class="d-flex justify-content-center align-items-center">
            <b-navbar-brand :to="{ name: 'contracts' }">
              <img src="@/assets/images/logo-2.svg" alt="" id="logo" width="150">
            </b-navbar-brand>
          </b-col>

          <b-col class="d-flex justify-content-end align-items-center text-light">
            <b-img v-show="!mobile" :src="user.picture" v-if="user.picture.length > 0" id="imgUser" rounded="circle"></b-img>
            <b-button @click="handleLogout" variant="default" class="text-light">
              <i class="fas fa-sign-out-alt mr-2"></i> Sair
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-navbar>
    <div id="nav">
      <div class="row h-100">
        <navbar-left :menuHide="menuHide"/>
        <div id="main" class="p-5 mt-4" :class="{ 'main-full': menuHide }">
          <div class="d-flex justify-content-between">
            <h4 style="margin-left: -15px">{{ $route.meta.title }}</h4>
            <b-button variant="default" @click="$router.go(-1)">
              <i class="fas fa-arrow-left"></i> Voltar
            </b-button>
          </div>
          <vue-page-transition name="flip-y" class="mt-3">
            <router-view/>
          </vue-page-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'navbar-left': () => import('@/components/layout/menu-left'),
  },
  data() {
    return {
      menuHide: true,
      rotate: false,
      mobile: false,
    };
  },
  computed: {
    user() {
      if (localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
      return { picture: '' };
    },
  },
  methods: {
    isMobile() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width <= 760) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },

    toggleMenu() {
      this.menuHide = !this.menuHide;
      this.rotate = !this.rotate;
    },
    handleLogout() {
      localStorage.removeItem('importer-auth');
      this.$router.push({name: 'login'});
    },
  },
  watch: {
    $route() {
      this.menuHide = true;
    },
  },
  created() {
    this.isMobile();
  },
};
</script>

<style>
html, body, #app, #nav, #main{
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
}
#main{
  width: calc(100% - 270px);
  margin-left: 270px;
  background: whitesmoke;
  transition: all ease 0.5s;
}

.card{
  background: #fff;
}

.title-card{
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
  font-weight: 300;
}

.title-card i{
  color: #5A8DEE;
  background-color: #eee;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
}

.table thead th{
  border-bottom-width: 1px !important;
}

html, body{
  overflow-x: hidden;
  background: whitesmoke;
}

.was-validated .form-control:valid, .form-control.is-valid{
  border-color: #ced4da !important;
  background-image: none !important;
  box-shadow: none !important;
}

.card-header {
  background-color: #5A8DEE !important;
  color: #fff;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  transform: rotate(-90deg);
  transition: all ease 0.5s;
}

.rotate-90{
  transform: rotate(-90deg);
  transition: all ease 0.5s;
}

.autocomplete__inputs input:focus{
  box-shadow: none !important;
}
.autocomplete--clear{
  margin-top: -13px;
}

.btn-primary{
  background-color: #6769f0 !important;
  border-color: #6769f0 !important;
}

.btn-info{
  background-color: #00CFDD !important;
  border-color: #00CFDD !important;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
}

.btn-danger{
  background-color: #FF5B5C !important;
  border-color: #FF5B5C !important;
}

.btn-success{
  background-color: #00d39b !important;
  border-color: #00d39b !important;
}

.btn-warning {
  border-color: #fedd00 !important;
  background-color: #fedd00 !important;
}

.was-validated .custom-select:valid, .custom-select.is-valid{
  border-color: #ced4da !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px !important;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus{
  box-shadow: none !important;
}

#menu-top {
  z-index: 9;
}
#logo {
  width: auto;
  height: 30px;
  position: absolute;
}

.main-full{
  margin-left: 0 !important;
  width: 100% !important;
  transition: all ease 0.6s;
}

#menu-top-container button{
  float: right;
  color: #eee;
  font-size: 20px;
  margin-right: 5px;
}

.button-middle{
  margin-top: 12px;
}

.rotate{
  transform: rotate(-90deg);
  transition: all ease 0.5s !important;
}

#menu-top{
  background-color: #6769f0 !important;
}

.navbar-brand{
  display: contents !important;
}

@media screen and (max-width: 768px) {
  #main{
    width: 100% !important;
  }
}
#imgUser {
  width: 40px;
}

.hide {
  display: none;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
}
</style>