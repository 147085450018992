import Vue from 'vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VuePageTransition from 'vue-page-transition';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Moment from 'vue-moment';
import VueIziToast from 'vue-izitoast';
import Vuelidate from 'vuelidate';

import App from './App.vue'
import router from './router';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'izitoast/dist/css/iziToast.min.css';

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);
Vue.use(VuePageTransition);
Vue.use(Moment);
Vue.use(VueIziToast);
Vue.use(Vuelidate);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
