<template>
  <div id="login-container">
    <loading :enable="loading" />
    <div class="container d-flex justify-content-center">
      <div class="col-md-4">
        <div class="w-100 d-flex justify-content-center">
          <img src="@/assets/images/logo-2.svg" alt="">
        </div>
        <div v-if="!forgetPassword">
          <b-form-group label="E-mail" class="text-light">
            <b-form-input
              type="email"
              v-model="user.email"
              v-on:keyup.enter="handleLogin"
              class="{ 'form-group--error': $v.user.email.$error }"
              :state="$v.user.email.$dirty ? !$v.user.email.$error : null"/>
          </b-form-group>
          <b-form-group label="Senha" class="text-light">
            <b-form-input
              v-on:keyup.enter="handleLogin"
              type="password"
              v-model="user.password"
              class="{ 'form-group--error': $v.user.password.$error }"
              :state="$v.user.password.$dirty ? !$v.user.password.$error : null"/>
          </b-form-group>
          <div class="w-100 d-flex justify-content-center">
            <button class="login-btn" @click="handleLogin">ENTRAR</button>
          </div>
        </div>
        <div v-else>
          <h5 class="text-center text-light">Esqueci minha senha</h5>
          <b-form-group label="E-mail" class="text-light">
            <b-form-input
              type="email"
              v-model="user.email"
              class="{ 'form-group--error': $v.user.email.$error }"
              :state="$v.user.email.$dirty ? !$v.user.email.$error : null"/>
          </b-form-group>
          <div class="w-100 d-flex justify-content-center">
            <button class="login-btn" @click="handleSendResetPassword">ENVIAR</button>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <button class="forget-password-btn" @click="handleForgetPassword">Cancelar</button>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<style lang="css">
  #login-container {
    background: #6769f0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 999;
  }

  img {
    width: 50%;
  }

  .forget-password-btn {
    color: white;
    background: #00d39b;
    font-weight: 100;
    border: none;
  }

  .login-btn {
    margin-top: 20px !important;
    border: none;
    background: #00d39b;
    color: #fff !important;
    font-weight: 100;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 6px;
    display: inline-block;
    transition: all 0.3s ease 0s;
    width: 50%;
    border-radius: 5px;
  }

  .login-btn:hover {
    color: #fff !important;
    font-weight: 700 !important;
    letter-spacing: 3px;
    background: #00d39b;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.3s ease 0s;
  }

</style>

<script>
import { required, email } from 'vuelidate/lib/validators';
import ErrorMixin from '@/mixins/error-mixin';
import MessageMixin from '@/mixins/message-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import Loading from '@/components/shared/loading.vue';

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      forgetPassword: false,
      user: {},
    };
  },
  mixins: [ErrorMixin, MessageMixin, QuestionMixin],
  components: {
    Loading,
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    async handleLogin() {
      this.$v.user.$touch();
      if (!this.$v.user.$invalid) {
        this.loading = true;
        if(this.user.email === 'admin@sub100.com.br' && this.user.password === 'bf:P5z#4n0}2kH[y') {
          localStorage.setItem('importer-auth', 'bf:P5z#4n0}2kH[y');
          await this.$router.push({name: 'contracts'});
        }
        this.loading = false;
      }
    },
    handleLogout() {
      localStorage.removeItem('importer-auth')
    },
    isLoggedIn() {
    },
    handleForgetPassword() {
      this.forgetPassword = !this.forgetPassword;
    },
    async handleSendResetPassword() {
      this.$v.user.email.$touch();
      if (!this.$v.user.email.$invalid) {
        this.loading = true;
        this.loading = false;
      }
    },
  },
  created() {
    if (localStorage.getItem('importer-auth') === 'bf:P5z#4n0}2kH[y') {
      this.$router.push({ name: 'contracts' });
    }
  },

};
</script>
