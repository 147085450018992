import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/login'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'contracts',
    component: () => import('@/components/contracts/index'),
    meta: {
      title: 'Gestão de Contratos',
    },
  },
  {
    path: '/highlights',
    name: 'highlights',
    component: () => import('@/components/highlights/index'),
    meta: {
      title: 'Conferência de destaques',
    },
  },
  {
    path: '/ensure-quality',
    name: 'ensure-quality',
    component: () => import('@/components/quality/index'),
    meta: {
      title: 'Qualidade dos cadastros',
    },
  },
  {
    path: '/digital-fairs',
    name: 'digital-fairs',
    component: () => import('@/components/fairs/index'),
    meta: {
      title: 'Feiras digitais',
    },
  },
  {
    path: '/importer-logs',
    name: 'importer-logs',
    component: () => import('@/components/logs/index'),
    meta: {
      title: 'Logs de Importação',
    },
  },
  {
    path: '/importer-logs/details/:lot/:client',
    name: 'importer-logs-details',
    component: () => import('@/components/logs/details'),
    meta: {
      title: 'Logs de Importação - Detalhes',
    },
  },
  {
    path: '/client-errors',
    name: 'client-errors',
    component: () => import('@/components/logs/client'),
    meta: {
      title: 'Clientes com erros de importação',
    },
  },
  {
    path: '/client-details/:client',
    name: 'client-details',
    component: () => import('@/components/logs/clientDetails.vue'),
    meta: {
      title: 'Erros de importação por cliente',
    },
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import('@/components/banners/index'),
    meta: {
      title: 'Banners',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (to.name === 'auth0-callback' || to.name === 'login') {
//     next();
//   } else if (router.app.$auth.isAuthenticated()) {
//     if (!to.meta.permissions) {
//       next();
//     } else if (await router.app.$auth.validatePermission(to.meta.permissions)) {
//       next();
//     } else {
//       router.push({ name: 'home' });
//     }
//   } else {
//     router.app.$auth.logout();
//   }
// });


export default router;
